import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingSpinner from "../layout/LoadingSpinner";



const Home = lazy(() => import("../pages/Home"));
const Welcome = lazy(() => import("../components/Welcome.js"))
const News = lazy(() => import("../components/News.js"));
const Programming = lazy(() => import("../components/Programming.js"));
const ImagesTab = lazy(() => import("../components/ImagesTab.js"));
const ProgrammingShowPage = lazy(() => import("../components/ProgrammingShowPage.js"));
const Login = lazy(() => import("../components/Login.js"));
const Logout = lazy(() => import("../components/LogOut.js"));
const Register = lazy(() => import("../components/Register.js"));
const ForgotPassword = lazy(() => import("../components/ForgotPassword.js"));
const SeeAllPhotos = lazy(() => import("../components/SeeAllPhotos.js"));
const TalentSearchProgramming = lazy(() => import("../components/TalentSearchProgramming.js"));
const StuntLandingPage = lazy(() => import("../components/StuntLandingPage.js"));
const UpdatedImagesTab = lazy(() => import("../components/UpdatedImagesTab.js"));
const UpdatedProgrammingShowPage = lazy(() => import("../components/UpdatedProgrammingShowPage.js"))
const MediaRequests = lazy(() => import("../components/MediaRequests.js"))



const ContentRouter = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Switch>


                {/* <Route exact path="/">
                    <Redirect to="/hallmark-channel" />
                </Route> */}

                <Route exact path="/Welcome" component={Welcome}/>

                <Route exact path="/Login" component={Login}/>
                <Route exact path="/Logout" component={Logout}/>
                <Route exact path="/Register" component={Register}/>
                <Route exact path="/ForgotPassword" component={ForgotPassword} />
                <Route exact path="/talentSearchProgramming/talent=:talent" component={TalentSearchProgramming}/>
                <Route exact path="/StuntLandingPage/Stunt=:stunt&channel=:channel" component={StuntLandingPage}/>

                <Route exact path="/SeeAllPhotos/Program=:program&episode=:episode" component={SeeAllPhotos}/>

                { <Route exact path="/" component={Home} /> }
                <Route exact path="/:Channel" component={Home} />

                <Route exact path="/hallmark-channel/MediaRequests" component={MediaRequests} />
                
                

                <Route exact path="/hallmark-channel/news" component={News} />
                {/* <Route exact path="/hallmark-movies-mysteries/news" component={News} /> */}
                <Route exact path="/hallmark-mystery/news" component={News} />
                <Route exact path="/hallmark-movies-now/news" component={News} />
                {/* <Route exact path="/hallmark-drama/news" component={News} /> */}
                <Route exact path="/hallmark-family/news" component={News} />
                <Route exact path="/hallmark-plus/news" component={News} />

               


                <Route exact path="/hallmark-channel/programming">
                    <Programming network={"Hallmark Channel"}></Programming>
                </Route>
                {/* <Route exact path="/hallmark-movies-mysteries/programming">
                    <Programming network={"Hallmark Movies & Mysteries"}></Programming>
                </Route> */}
                <Route exact path="/hallmark-mystery/programming">
                    <Programming network={"Hallmark Movies & Mysteries"}></Programming>
                </Route>
                <Route exact path="/hallmark-movies-now/programming">
                    <Programming network={"Hallmark Movies Now"}></Programming>
                </Route>
                {/* <Route exact path="/hallmark-drama/programming">
                    <Programming network={"Hallmark Drama"}></Programming>
                </Route> */}
                <Route exact path="/hallmark-family/programming">
                    <Programming network={"Hallmark Drama"}></Programming>
                </Route>
                <Route exact path="/hallmark-plus/programming">
                    <Programming network={"Hallmark Plus"}></Programming>
                </Route>

                
                <Route exact path="/hallmark-channel/images">
                    <UpdatedImagesTab channel={"hallmark%20channel"}></UpdatedImagesTab>
                </Route>

                {/* <Route exact path="/hallmark-movies-mysteries/images">
                    <UpdatedImagesTab channel={"hallmark%20movies%20and%20mysteries"}></UpdatedImagesTab>
                </Route> */}
                <Route exact path="/hallmark-mystery/images">
                    <UpdatedImagesTab channel={"Hallmark%20Movies%20and%20Mysteries"}></UpdatedImagesTab>
                </Route>

                <Route exact path="/hallmark-movies-now/images">
                    <UpdatedImagesTab channel={"hallmark%20movies%20now"}></UpdatedImagesTab>
                </Route>

                {/* <Route exact path="/hallmark-drama/images">
                    <UpdatedImagesTab channel={"hallmark%20drama"}></UpdatedImagesTab>
                </Route> */}
                <Route exact path="/hallmark-family/images">
                    <UpdatedImagesTab channel={"hallmark%20drama"}></UpdatedImagesTab>
                </Route>

                <Route exact path="/hallmark-plus/images">
                    <UpdatedImagesTab channel={"hallmark%20plus"}></UpdatedImagesTab>
                </Route>


                <Route exact path="/hallmark-channel/programming/program=:programName&channel=:currentChannel&episode=:episode?" component={UpdatedProgrammingShowPage} />
                <Route exact path="/hallmark-channel/programming/program=:programName&channel=:currentChannel" component={UpdatedProgrammingShowPage} />

            </Switch>
        </Suspense>

    );
};

export default ContentRouter;